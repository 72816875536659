import React from 'react';
import Invest from 'pageComponents/Invest';
import { graphql } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { SEO } from 'components/SEO';

const InvestPage = () => {
  const { t } = useI18next();
  return (
    <>
      <SEO title={t('home.Header.Invest')} />
      <Invest />
    </>
  );
};

export default InvestPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
