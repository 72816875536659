import React from 'react'
import * as Styles from "./styles";

import ButtonCTA from 'components/ButtonCTA';
import PageHero from 'components/PageHero';

import { Trans, useTranslation } from 'react-i18next';
import NavigationColor from 'utils/constants/NavigationColor';


const Header = () => {
  const { t } = useTranslation();
  
  return (
      <PageHero navigationColorTheme={NavigationColor.DARK}>
        <Styles.ContainerTop>
          <Styles.MainTitle>
            <Trans i18nKey={"Invest.ATF.Title"} />
          </Styles.MainTitle>
            <Styles.TextContentTop>
              {t('Invest.ATF.Description')}
            </Styles.TextContentTop>
            <ButtonCTA goldHover>{t('home.ATF.cta')}</ButtonCTA>
        </Styles.ContainerTop>
      </PageHero>
  );
};

export default Header;