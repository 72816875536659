import React from 'react'
import * as Styles from "./styles";

import Divider from "images/newDividerSingle.svg";

import { Trans, useTranslation } from 'react-i18next';



const HowItWorksFeatures = [
  {
    title: 'home.Header.Invest',
    text: 'Invest.How_It_Works.Features.Invest.description',
  },
  {
    title: 'Invest.How_It_Works.Features.Earn.title',
    text: 'Invest.How_It_Works.Features.Earn.description',
  },
  {
    title: 'Invest.How_It_Works.Features.Reinvest.title',
    text: 'Invest.How_It_Works.Features.Reinvest.description',
  },
];


interface ParagraphsProps {
  element: {
  title: string;
  text: string;
  };
}

const Paragraphs = ({element}: ParagraphsProps) => {
  const { t } = useTranslation();

  const { title, text} = element;

  return (
    <div>
      <Styles.RightTitle>
        <Trans i18nKey={title} />
      </Styles.RightTitle>
      <Styles.RightText>{t(text)}</Styles.RightText>
    </div>
  );
};


const InvestSection1 = () => {
  const { t } = useTranslation();

  return (
    <Styles.WrapperSection1>
          <Styles.LeftContent>
            <img src={Divider} alt="" aria-hidden="true" />
              <Styles.LeftTitle>
                <Trans i18nKey={'Invest.How_It_Works.Content.Title'} />
              </Styles.LeftTitle>
              <Styles.LeftText>
               {t('Invest.How_It_Works.Content.Description')}
              </Styles.LeftText>
          </Styles.LeftContent>
          <Styles.RightContent>
              {HowItWorksFeatures.map((item) => (
                <Paragraphs element={item} key={item.title} />
            ))}
          </Styles.RightContent>
    </Styles.WrapperSection1>
  );
};

export default InvestSection1;
