import React from 'react';
import * as Styles from "./styles";

import IconCashOut from 'images/IconCashOut.svg';
import IconMarketPlace from 'images/IconMarketPlace.svg';
import IconRisk from 'images/IconRisk.svg'

import Section from 'components/Section';
import Box from 'components/Box';

import { Trans, useTranslation } from 'react-i18next';


const InvestLiquidityCards = [
    {
      title: 'Invest.Liquidity.Content.Snippets.Marketplace.Title',
      text: 'Invest.Liquidity.Content.Snippets.Marketplace.Description',
      icon: IconMarketPlace,
    },
    {
      title: 'Invest.Liquidity.Content.Snippets.Cash_out.Title',
      text: 'Invest.Liquidity.Content.Snippets.Cash_out.Description',
      icon: IconCashOut,
    },
    {
      title: 'Invest.Liquidity.Content.Snippets.Manage_Risk_Smartly.Manage_risk_smartly',
      text: 'Invest.Liquidity.Content.Snippets.Manage_Risk_Smartly.Title',
      icon: IconRisk,
    },
  ];

interface CardProps {
    element: {
    title: string;
    text: string;
    icon: any;
  };
}

const Card = ({element}: CardProps) => {
    const { t } = useTranslation();
    
    const {title, text, icon} = element;

    return (
        <Styles.CardContainer>
            <img src={t(icon)} alt="" aria-hidden="true" />
            <Styles.CardsHeading>
              <Trans i18nKey={title} /> 
            </Styles.CardsHeading>
            <Styles.CardsText>
              <Trans i18nKey={text} /> 
            </Styles.CardsText>
        </Styles.CardContainer>
    )
}


const InvestSection2 = () => {
  const { t } = useTranslation();


  return (
        <Styles.Section2Wrapper>
          <Section>
            <Box>
              <Styles.TitleSection2>
                <Trans i18nKey={'Invest.Liquidity.Content.Title'} />
              </Styles.TitleSection2>
              <Styles.CardsSection2>
                {InvestLiquidityCards.map((item) => (
                  <Card element={item} key={item.title} />
              ))}
              </Styles.CardsSection2>
            </Box>
          </Section>
        </Styles.Section2Wrapper>
  );
};

export default InvestSection2;