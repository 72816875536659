import React from 'react';
import styled from 'styled-components';

const StyledBox = styled.div`
  padding: 30px 272px 0 272px;
  max-width: 1440px;

  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    padding: 30px 24px 0 24px;
  }
`;

interface Props {
  children: React.ReactNode;
}

const Box = ({ children }: Props) => {
  return <StyledBox>{children}</StyledBox>;
};

export default Box;
