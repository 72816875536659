import styled from 'styled-components';
import { rem } from 'polished';

export const ContainerTop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 150px;
  padding-bottom: ${rem(80)};

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: 18px 10px;
    padding-bottom: ${rem(48)};
  }
`;

export const MainTitle = styled.h1`
  font-size: ${rem(48)};
  font-weight: ${(props) => props.theme.fontWeights.normal};
  line-height: ${rem(66)};
  color: ${(props) => props.theme.colors.tertiary};
  margin-top: ${rem(70)};
  padding-top: ${rem(60)};

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: ${rem(36)};
    line-height: ${rem(48)};
    padding: 10px 65px 10px 65px;
  }
`;

export const TextContentTop = styled.p`
  font-size: ${rem(18)};
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  line-height: ${rem(30)};
  margin-bottom: ${rem(50)};
  padding-top: ${rem(30)};
  color: ${(props) => props.theme.colors.tertiary};
  opacity: 0.7;
  text-align: justify;

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding-top: ${rem(16)};
    padding-bottom: ${rem(18)};
  }
`;

// section 1

export const WrapperSection1 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 60px 272px 40px 272px;
  margin-top: ${rem(45)};

  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    padding: 40px 20px 10px 20px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-content: stretch;
    padding: 40px 10px 10px 10px;
  }
`;

export const LeftContent = styled.div`
  max-width: ${rem(400)};
  height: ${rem(410)};
  margin-bottom: ${rem(30)};
  margin-right: ${rem(30)};

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    width: 100%;
    height: ${rem(145)};
    margin-bottom: ${rem(10)};
    margin-right: 0;
  }
`;

export const LeftTitle = styled.h3`
  font-weight: ${(props) => props.theme.fontWeights.normal};
  font-size: ${rem(32)};
  line-height: ${rem(46)};
  color: ${(props) => props.theme.colors.tertiary};
  margin: 0 auto;
  padding-top: ${rem(18)};

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin-bottom: ${rem(16)};
  }
`;

export const LeftText = styled.p`
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  font-size: ${rem(16)};
  line-height: ${rem(30)};
  opacity: 0.7;
  padding-top: ${rem(35)};

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    display: none;
  }
`;

// section 1 right part

export const RightContent = styled.div`
  width: ${rem(400)};
  height: ${rem(410)};
  padding-top: ${rem(38)};
  margin-left: ${rem(35)};

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    width: 100%;
    margin-bottom: ${rem(20)};
    margin-left: 0;
  }
`;

export const RightTitle = styled.h4`
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${rem(20)};
  line-height: ${rem(30)};
  color: ${(props) => props.theme.colors.tertiary};
  padding-bottom: ${rem(16)};

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: ${rem(18)};
    padding-bottom: ${rem(28)};
  }
`;

export const RightText = styled.p`
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  font-size: ${rem(18)};
  line-height: ${rem(30)};
  opacity: 0.7;
  padding-bottom: ${rem(35)};

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: ${rem(16)};
    line-height: ${rem(24)};
    padding-bottom: ${rem(16)};
  }
`;

export const TitleSection2 = styled.h3`
  font-size: ${rem(32)};
  font-weight: ${(props) => props.theme.fontWeights.normal};
  line-height: ${rem(46)};
  margin: 0 0 16px 0;
  text-align: center;

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: ${rem(26)};
    line-height: ${rem(39)};
  }
`;

export const CardsSection2 = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${rem(80)};
  margin-top: ${rem(80)};

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    display: flex;
    flex-direction: column;
  }
`;

export const CardsHeading = styled.h3`
  font-weight: ${(props) => props.theme.fontWeights.normal};
  font-size: ${rem(24)};
  line-height: ${rem(36)};
  margin-bottom: ${rem(16)};

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: ${rem(20)};
    line-height: ${rem(30)};
  }
`;

export const CardsText = styled.p`
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  margin: 0;
`;

export const Section2Wrapper = styled.div`
  padding-top: ${rem(180)};
  padding-bottom: ${rem(80)};
  color: ${(props) => props.theme.colors.tertiary};
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    opacity: 0.7;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding-top: ${rem(60)};
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  img {
    width: ${rem(80)};
    height: ${rem(80)};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: stretch;
  }
`;
