import React from 'react';
import Header from './components/Header';
import InvestSection1 from './components/InvestSection1';
import InvestSection2 from './components/InvestSection2';
import DirectSignup from 'components/DirectSignup';

const Invest = () => {
  return (
    <>
      <Header />
      <InvestSection1 />
      <InvestSection2 />
      <DirectSignup />
    </>
  );
};

export default Invest;
